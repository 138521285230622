import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import MainPage from './components/pages/gallery/MainPage/MainPage';
// import WishListPage from './components/pages/gallery/WishListPage/WishListPage';
import OrderPage from './components/pages/OrderPage/OrderPage';
import ProfilePage from './components/pages/gallery/ProfilePage/ProfilePage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import getExhibitions from './redux/requests/getExhibitions';

import ContactsPage from './components/pages/gallery/ContactsPage/ContactsPage';
import ConfirmPage from './components/pages/gallery/ConfirmPage/ConfirmPage';
import PrimaryPage from './components/pages/events/PrimaryPage/PrimaryPage';
import GridPage from './components/pages/showroom/GridPage/GridPage';
import CategoryPage from './components/pages/showroom/CategoryPage/CategoryPage';
import ShopPage from './components/pages/showroom/ShopPage/ShopPage';
import ProductPage from './components/pages/showroom/ProductPage/ProductPage';
import ShowroomOrderItem from './components/pages/showroom/ShowroomOrderItem/ShowroomOrderItem';
import OrderItem from './components/pages/OrderPage/OrderItem';
import PagePainters from './components/pages/painters/PagePainters/PagePainters';
import ProfilePainter from './components/pages/painters/ProfilePainter/ProfilePainter';
import ArchivePage from './components/pages/gallery/ArchivePage/ArchivePage';
import SingleArchivePage from './components/pages/gallery/SingleArchivePage/SingleArchivePage';
import Header from './components/elements/Header/Header';
import Container from './components/templates/Container';
import AllExhibitionsPage from './components/pages/all_exhibitions/AllExhibitionsPage';
import VkAuth from './components/pages/VkAuth/VkAuth';
import { setNewItemToOrderAction } from './redux/reducers/orderReducer';
import { setNewItemToShowroomOrderAction } from './redux/reducers/showroomOrderReducer';
import MobilePrivacy from './components/pages/MobilePrivacy/MobilePrivacy';
import TgAuth from './components/pages/TgAuth/TgAuth';
import Auth from './components/pages/Auth/Auth';
import AppleAuth from './components/pages/AppleAuth/AppleAuth';
import CouponsUsage from './components/pages/CouponsUsage/CouponsUsage';
import Cluster from './components/pages/Cluster/Cluster';
import Residents from './components/pages/Residents/Residents';
import SelectionPage from './components/pages/showroom/CategoryPage/SelectionPage/SelectionPage';

function addProductToOrder(order, setOrder, product) {
  let old_order = { ...order };
  if (old_order[product.title]) {
    old_order[product.title].quantity += 1;
  } else {
    old_order[product.title] = { quantity: 1, product: product };
  }
  setOrder(old_order);
}

function removeProductFromOrder(order, setOrder, product) {
  let old_order = { ...order };
  delete old_order[product.title];
  setOrder(old_order);
}

function changeProductQuantity(order, setOrder, product, quantity) {
  let old_order = { ...order };
  old_order[product.title].quantity = quantity;
  setOrder(old_order);
}

function App() {
  const dispatch = useDispatch();
  const { pathname, hash, key, search } = useLocation();
  const [backLink, setBackLink] = useState('');

  const [galleryOrder, setGalleryOrder] = useState([]);
  const [showroomOrder, setShowroomOrder] = useState([]);

  const orderListFromRedux = useSelector((state) => state.order.orderList);
  const showroomOrderListFromRedux = useSelector(
    (state) => state.showroomOrder.showroomOrderList
  );

  const addProduct = (product) => {
    if (pathname.includes('showroom')) {
      dispatch(setNewItemToShowroomOrderAction(product));
    } else {
      dispatch(setNewItemToOrderAction(product));
    }
  };

  useEffect(() => {
    if (search.includes('utm'))
      window.localStorage.setItem('UTM_string', search.split('?')[1]);
  }, [search]);

  useEffect(() => {
    window.localStorage.setItem(
      'place_gallery_orders',
      JSON.stringify(orderListFromRedux)
    );
    window.localStorage.setItem(
      'place_showroom_orders',
      JSON.stringify(showroomOrderListFromRedux)
    );
  }, [orderListFromRedux, showroomOrderListFromRedux]);

  useEffect(() => {
    dispatch(getExhibitions());
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [dispatch, pathname, hash, key]);

  return (
    <>
      <Container>
        <Header galleryOrder={galleryOrder} showroomOrder={showroomOrder} />
      </Container>

      <Routes>
        <Route path='/' element={<PrimaryPage />} />
        <Route path='/mobile_privacy' element={<MobilePrivacy />} />
        <Route
          path='/gallery/:id'
          element={
            <MainPage orderDict={galleryOrder} setBackLink={setBackLink} />
          }
        />
        <Route path={'/gallery'} element={<AllExhibitionsPage />} />
        {/* <Route path='/gallery/wishlist' element={<WishListPage />} /> */}
        <Route
          path='gallery/order'
          element={
            <OrderPage
              url='exhibitions/payments/'
              orderDict={galleryOrder}
              OrderItemComponent={OrderItem}
              onClose={(product) => {
                removeProductFromOrder(galleryOrder, setGalleryOrder, product);
              }}
              onChange={(product, quantity) => {
                changeProductQuantity(
                  galleryOrder,
                  setGalleryOrder,
                  product,
                  quantity
                );
              }}
              backLink='/gallery/'
            />
          }
        />
        <Route
          path='/gallery/confirm'
          element={
            <ConfirmPage
              localStorageVariableName={'paymentsIndustry'}
              url={'exhibitions/payments/'}
            />
          }
        />

        <Route path='/vk-auth' element={<VkAuth />} />
        <Route path='/tg-auth' element={<TgAuth />} />
        <Route path='/apple-auth' element={<AppleAuth />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/coupons-usage' element={<CouponsUsage />} />
        <Route path='/cluster' element={<Cluster />} />
        <Route path='/audio' element={<Cluster />} />
        <Route path='/residents/:id' element={<Residents />} />

        <Route
          path='/gallery/payment_and_delivery'
          element={<ContactsPage orderDict={galleryOrder} />}
        />
        <Route
          path='/gallery/profile/:id'
          element={
            <ProfilePage
              orderDict={galleryOrder}
              addProduct={(product) => {
                addProduct(product);
              }}
              backLink={backLink}
            />
          }
        />
        <Route
          path='/showroom'
          element={<GridPage orderDict={showroomOrder} />}
        />
        <Route
          path='/showroom/categories/:id'
          element={<CategoryPage orderDict={showroomOrder} />}
        />
        <Route
          path='/showroom/categories'
          element={<CategoryPage orderDict={showroomOrder} />}
        />
        <Route
          path='/showroom/shops/:id'
          element={
            <ShopPage
              addProductToOrder={(product) => {
                addProductToOrder(showroomOrder, setShowroomOrder, product);
              }}
              orderDict={showroomOrder}
            />
          }
        />
        <Route
          path='/showroom/products/:id'
          element={
            <ProductPage
              addProduct={(product) => {
                addProduct(product);
              }}
              orderDict={showroomOrder}
            />
          }
        />
        <Route
          path='/showroom/confirm'
          element={
            <ConfirmPage
              localStorageVariableName={'paymentsShowroomIndustry'}
              url={'shops/payments/'}
            />
          }
        />
        <Route
          path='/showroom/order'
          element={
            <OrderPage
              isBlack={true}
              url='shops/payments/'
              orderDict={showroomOrder}
              OrderItemComponent={ShowroomOrderItem}
              onClose={(product) => {
                removeProductFromOrder(
                  showroomOrder,
                  setShowroomOrder,
                  product
                );
              }}
              onChange={(product, quantity) => {
                changeProductQuantity(
                  showroomOrder,
                  setShowroomOrder,
                  product,
                  quantity
                );
              }}
              backLink='/showroom/'
            />
          }
        />
        <Route
          path='/painters/'
          element={<PagePainters setBackLink={setBackLink} />}
        />
        <Route
          path='/painters/:id'
          element={<ProfilePainter setBackLink={setBackLink} />}
        />
        <Route path='/archive/' element={<ArchivePage />} />
        <Route path='/archive/:id' element={<SingleArchivePage />} />
        <Route path='/showroom/banner/:id' element={<SelectionPage />} />
      </Routes>
    </>
  );
}

export default App;
