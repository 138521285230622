import React, { useEffect, useState, useRef } from 'react';
import styles from './SelectionPage.module.scss';
import { getSelection } from '../../../../../redux/requests/getSelection';

import { useLocation, useParams, useNavigate } from 'react-router-dom';
import PageTemplate from '../../../../templates/PageTemplate';
import Container from '../../../../templates/Container';
import productPlaceholder from '../../../../../assets/img/square_placeholder.jpg';


const Selection = ({ orderDict }) => {
  const navigate = useNavigate();

  let [selection, setSelection] = useState([]);
  let location = useLocation();
  useEffect(() => {
    let banner_id = location.pathname.split('/').pop() 
    getSelection(banner_id).then((res) => {
      setSelection(res)
      console.log(res)
    });
  }, [location]);

  const clickOrLink = (e, id) => {
    if (e.target.className.includes('productButton')) {
      return;
    }
    return navigate(`/showroom/products/` + id, {state: {from: `/showroom/banner/${selection.banner_id}`} });
  };



  return (
    <div className={styles.wrapper}>
      <PageTemplate
        header='absolute'
        orderLink='/showroom/banner'
        order={true}
        orderDict={orderDict}
      >
        <Container>
          <h2 className={styles.bannerTitle}>{selection && selection.banner_title}</h2>
          <div className={styles.shop}>
            <div className={styles.products}>
              {selection &&
                selection.result?.sort((first, second) => first.order_index - second.order_index)
                .map((item) => (
                  <div
                    key={item.product.id}
                    className={styles.productLink}
                    onClick={(e) => clickOrLink(e, item.product.id)}
                  >
                    <div className={styles.productWrapper}>
                      <div className={styles.product}>
                        <div className={styles.productReadMore}>Подробнее</div>
                        <div className={styles.productInfo}>
                          <div className={styles.productTitle}>
                            {item.product.title}
                          </div>
                          <div className={styles.productCost}>
                            {String(item.product.price).replace(
                              /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
                              '$1 '
                            )}
                            р.
                          </div>
                        </div>
                        <div className={styles.imageWrapper}>
                          <img
                            src={`${
                              item.product.cropped_image || productPlaceholder
                            }`}
                            alt={item.product.title}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Container>
      </PageTemplate>
    </div>
  );
};

export default Selection;