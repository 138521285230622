import { useEffect, useRef, useState } from 'react';
import styles from './Baners.module.scss';
import Slider from 'react-slick';
import { getBaners } from '../../../../../redux/requests/getBaners';
import { NavLink, useLocation } from 'react-router-dom';
import useMobileDetect from "use-mobile-detect-hook";



export const Baners = () => {
    const slider = useRef()
    const [ baners, setBaners ] = useState([])
    const location = useLocation()
    const detectMobile = useMobileDetect();
    const isMobile = detectMobile.isMobile();


    useEffect(() => {
        getBaners().then((res) => {
            setBaners(res.results);
        });
    
    }, [location]);
    
    let settings = {
        dots: false,
        infinite: true,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
    };

    
    return (
        <div className={styles.slick}>
            <Slider {...settings} ref={slider}>
                {baners
                .sort((first, second) => first.order_index - second.order_index)
                .map((banner) => (
                    <div
                        className={styles.text}
                        key={banner.id}
                        onClick={() => {
                            // onClickCallback(category.id);
                    }}>
                        {/* {category.title} */}
                        <NavLink to={`/showroom/banner/${banner.id}`} className={({ isActive }) => (isActive ? styles.pressed : '')}>
                            <img
                                src={
                                    banner.banner
                                }
                                alt={banner.title}
                                style={isMobile ? { maxWidth: "100%" } : { maxWidth: "100%" }}
                                // ref={imageWidthRef}
                                // onLoad={() => setIsLoad(true)}
                                // className={styles.fakeImage}
                            />
                        </NavLink>
                        
                    </div>
                ))}
            </Slider>
        </div>
    )
}