import React, { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import styles from './ProductPage.module.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import PageTemplate from '../../../templates/PageTemplate';
import Container from '../../../templates/Container';
import { getProduct } from '../../../../redux/requests/getProduct';
import useMobileDetect from 'use-mobile-detect-hook';
import { ReactComponent as GoBackArrow } from '../../../../assets/img/arrow-left.svg';
import { ReactComponent as MobileGoBackArrow } from '../../../../assets/img/showroom/goBackMobile.svg';
import ProductImages from './ProductImages/ProductImages';
import OrderModal from '../../../elements/OrderModal/OrderModal';
import { useSelector } from 'react-redux';

const ProductPage = ({ addProduct, orderDict }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  let params = useParams();
  let location = useLocation();
  let [images, setImages] = useState([]);
  let [product, setProduct] = useState({});
  let [isModalActive, setIsModalActive] = useState(false);
  let [isButtonActive, setIsButtonActive] = useState(true);
  const showroomOrderListFromRedux = useSelector(
    (state) => state.showroomOrder.showroomOrderList
  );

  const addToOrder = (product) => {
    if (product.count) {
      return;
      // addProduct(currentProfile)
    } else {
      addProduct({ ...product, count: 1 });
    }
  };

  useEffect(() => {
    getProduct(params.id).then((res) => {
      setProduct(res);
      let images = [
        { id: 0, image: res.image, cropped_image: res.cropped_image },
        ...res.additional_photos,
      ];
      setImages(images);
    });

    if (showroomOrderListFromRedux.some((point) => point.id === product.id)) {
      setIsButtonActive(false);
    }
  }, [location, orderDict, showroomOrderListFromRedux]);
  let openModal = () => {
    setIsModalActive(true);
  };

  return (
    <div className={styles.wrapper}>
      {isModalActive && (
        <OrderModal
          onClose={() => {
            setIsModalActive(false);
          }}
          url={'shops/payments/'}
          product_ids={product.id + ':1'}
          localStorageVariableName={'paymentsShowroomIndustry'}
        />
      )}
      <PageTemplate
        header='absolute'
        orderLink='/showroom/order'
        order={true}
        orderDict={orderDict}
      >
        <Container>
          <div className={styles.backArrow}>
            {isMobile ? (
              <Link to={location.state ? location.state.from : `/showroom/shops/${product.shop?.id}`}>
                {' '}
                <MobileGoBackArrow />
              </Link>
            ) : (
              <Link to={location.state ? location.state.from : `/showroom/shops/${product.shop?.id}`}>
                {' '}
                <GoBackArrow /> Назад
              </Link>
            )}
          </div>
          {product.shop && (
            <div className={styles.product}>
              <div className={styles.productImages}>
                <ProductImages images={images} title={product.title} />
              </div>
              <div className={styles.productWrapper}>
                <div className={styles.productTitle}>{product.title}</div>
                <div className={styles.shop}>{product.shop.title}</div>

                <div className={styles.productDescription}>
                  <div className={styles.productDescriptionText}>
                    {product.description &&
                      HTMLReactParser(product.description)}
                  </div>
                </div>
                <div className={styles.price}>
                  <div className={styles.productCost}>
                    {String(product.price).replace(
                      /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
                      '$1 '
                    )}{' '}
                    ₽
                  </div>

                  {!product.is_offline_only && (
                    <button
                      type='button'
                      className={styles.productButton}
                      disabled={(product.quantity < 1) | !isButtonActive}
                      onClick={() => {
                        addToOrder(product);
                      }}
                    >
                      {product.quantity > 0
                        ? isButtonActive
                          ? 'Купить'
                          : 'В корзине'
                        : 'Нет в наличии'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </PageTemplate>
    </div>
  );
};

export default ProductPage;
