import React, { useEffect, useState } from 'react';
import styles from './EventSection.module.scss';

import Container from '../../../templates/Container';
import PromoSlider from './Slider/PromoSlider';
// import { getEvents } from '../../../../redux/requests/getEvents';

import useMobileDetect from 'use-mobile-detect-hook';
import classnames from '../../../../hooks/classnames';

const EventSection = ({
  promoTitle,
  backgroundImage,
  backgroundImageCrop,
  promoDates,
  promoCity,
  promoPlace,
  registrationLink,
  text,
}) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  return (
    <div
      className={classnames([styles.promo, [styles.promo__mobile, isMobile]])}
      style={
        isMobile
          ? { backgroundImage: `url('${backgroundImageCrop}')` }
          : { backgroundImage: `url('${backgroundImage}')` }
      }>
      <Container>
        <div className={classnames([styles.container, [styles.container__mobile, isMobile]])}>
          <div className={styles.wrapper}>
            <h1 className={classnames([styles.promo_title, [styles.promo_title__mobile, isMobile]])}>{promoTitle}</h1>

            {isMobile && (
              <>
                <p className={classnames([styles.city, [styles.city__mobile, isMobile]])}>{promoCity}</p>
                <p className={classnames([styles.dates, [styles.dates__mobile, isMobile]])}>{promoDates}</p>
                <p
                  className={classnames([styles.text, [styles.text__mobile, isMobile]])}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </>
            )}

            {registrationLink && (
              <a
                href={`${registrationLink}/#categories`}
                className={classnames([styles.regisrtation, [styles.regisrtation__mobile, isMobile]])}
                target="_blank"
                rel="noopener noreferrer">
                Зерегистрироваться
              </a>
            )}

            <p className={classnames([styles.place, [styles.place__mobile, isMobile]])}>{promoPlace}</p>
          </div>
          <div className={styles.wrapper}>
            {!isMobile && (
              <>
                <p className={classnames([styles.city, [styles.city__mobile, isMobile]])}>{promoCity}</p>
                <p className={classnames([styles.dates, [styles.dates__mobile, isMobile]])}>{promoDates}</p>
                <p
                  className={classnames([styles.text, [styles.text__mobile, isMobile]])}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </>
            )}
          </div>
          {/* <div className={classnames([styles.slider, [styles.slider__mobile, isMobile]])}> */}
          {/* <PromoSlider slides={logos} /> */}
          {/* </div> */}
        </div>
      </Container>
    </div>
  );
};

export default EventSection;
