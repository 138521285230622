import React, { useState, useEffect } from 'react';
import styles from './PromoSection.module.scss';
import MasterSection from '../MasterSection/MasterSection';
import EventSection from '../EventSection/EventSection';
import { getEvents } from '../../../../redux/requests/getEvents';

const PromoSection = () => {
  // const [logos, setLogos] = useState([]);
  const [blackscreen, setBlackscreen] = useState(true);

  let [promoTitle, setPromoTitle] = useState('');
  let [promoDates, setPromoDates] = useState('');
  let [promoCity, setPromoCity] = useState('');
  let [promoPlace, setPlace] = useState('');
  let [backgroundImage, setbackgroundImage] = useState('');
  let [backgroundImageCrop, setBackgroundImageCrop] = useState('');
  let [registrationLink, setRegistrationLink] = useState('');
  let [text, setText] = useState('');

  // const data =
  //   {
  //     id: 2,
  //     title: "Обучающая программа МЕНЕДЖМЕНТ И УПРАВЛЕНИЕ КРЕАТИВНЫМ БИЗНЕСОМ",
  //     background: "https://place.industry.art/media/events/cover_YitS5KX.jpg",
  //     cropped_image: "https://place.industry.art/media/events/cover_YitS5KX.jpg.828x1792_q85_box-911%2C0%2C1364%2C982_crop_detail.jpg",
  //     dates: "до 11 ноября 2024 года",
  //     main_location: "регистрация открыта",
  //     sub_location: "Повысьте эффективность  своего бизнеса!",
  //     registration_link: "https://university.industry.art",
  //     partners: [],
  //     text: "<ul>\r\n\t<li>обучение от федеральных экспертов</li>\r\n\t<li>разработка стратегии роста бизнеса</li>\r\n\t<li>поддержка трекеров</li>\r\n</ul>"
  //   }


  useEffect(() => {
    getEvents().then((res) => {
      let eventsData = res.results[0];
      setBlackscreen(false);
      if (res.results.length < 1) {
        // Если с бека не пришёл ни одно мероприятие, то не устанавливаем значение
        return;
      }
      setPromoTitle(eventsData.title);
      setbackgroundImage(eventsData.background);
      setBackgroundImageCrop(eventsData.cropped_image)
      setPromoDates(eventsData.dates);
      setPromoCity(eventsData.main_location);
      setPlace(eventsData.sub_location);
      setRegistrationLink(eventsData.registration_link);
      setText(eventsData.text);

      // setLogos(eventsData.partners);
    });
  }, []);

  return (
    <>
      {promoTitle ?
        <EventSection
          promoTitle={promoTitle}
          backgroundImage={backgroundImage}
          backgroundImageCrop={backgroundImageCrop}
          promoDates={promoDates}
          promoCity={promoCity}
          promoPlace={promoPlace}
          registrationLink={registrationLink}
          text={text}

        // promoTitle={data.title}
        // backgroundImage={'https://place.industry.art/media/events/cover_YitS5KX.jpg'}
        // backgroundImageCrop={'https://place.industry.art/media/events/cover_YitS5KX.jpg.828x1792_q85_box-911%2C0%2C1364%2C982_crop_detail.jpg'}
        // promoDates={data.dates}
        // promoCity={data.main_location}
        // promoPlace={data.sub_location}
        // registrationLink={data.registration_link}
        // text={data.text}


        // logos={logos}
        />
      : <MasterSection
          blackscreen={blackscreen}
        /> }
      {blackscreen ? <div className={styles.blackscreen} id='blackscreen'></div> : null}
    </>
  );
};

export default PromoSection;
